import Dygraph from "dygraphs/src-es5/dygraph";
import "dygraphs/src-es5/extras/synchronizer.js"
import "dygraphs/src-es5/extras/smooth-plotter.js"

import { InfluxDB } from '@influxdata/influxdb-client'
import { postgresqlurl, after_time, before_time, filter } from './env.mjs'

async function getInfluxCredentials() {
  try {
    // Fetch the InfluxDB credentials from the Rails API using the serial number
    const response = await fetch(`${postgresqlurl}/${transformer}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    // Handle any errors
    if (!response.ok) {
      throw new Error(`Error fetching InfluxDB credentials: ${response.statusText}`)
    }

    // Parse the JSON response to extract the InfluxDB credentials
    const { influx_url, influx_token, influx_org, influx_bucket } = await response.json()
    return { influx_url, influx_token, influx_org, influx_bucket }

  } catch (error) {
    console.error('Failed to fetch InfluxDB credentials:', error)
    throw error
  }
}

async function influxdb_connect() {
  try {
    // Retrieved the serial number from the URL parameter 'transformer'

    if (!transformer) {
      throw new Error('Serial number (transformer) is missing from the URL')
    }

    // Fetch InfluxDB credentials from the Rails API
    const { influx_url, influx_token, influx_org, influx_bucket } = await getInfluxCredentials()
    bucket = influx_bucket
    // Establish a connection to InfluxDB using the retrieved credentials
    return new InfluxDB({ url: influx_url, token: influx_token }).getQueryApi(influx_org)

  } catch (error) {
    console.error('Failed to connect to InfluxDB:', error)
  }
}

const waveform_loading_text = "Loading waveform data...";
const no_waveform_text = "No waveform data could be found for this event";
const cycle_loading_text = "Loading cycle...";
const no_cycle_text = "No cycle data could be found for this event";

async function get_cycle_fields(measurement) {
  let select_cycle_units = document.getElementById('cycles_units')
  const fluxQuery = `
  import "influxdata/influxdb/schema"
  schema.fieldKeys(
    bucket: "${bucket}",
    predicate: (r) => r._measurement == "${measurement}" and
                      r["Customer"] == "${customer}" and
                      r["Site"] == "${site}" and
                      r["Transformer"] == "${transformer}"
  )
`;
  const cycles_units_data = await influx.collectRows(fluxQuery);
  // console.log('get_cycle_fields', cycles_units_data);

  cycles_units_data.forEach((row) => {
    let option = document.createElement("option");
    option.value = row._value;
    option.text = row._value;
    if (row._value === 'VRms') {
      option.selected = 'selected';
    }
    select_cycle_units.appendChild(option);
  });
  // console.log('select_cycle_units', select_cycle_units);
  return select_cycle_units;
}

function legendFormatter(data) {
  if (data.x == null) {
    return '<br>' + data.series.map(function (series) {
      return series.dashHTML + ' ' + series.labelHTML
    }).join('<br>');
  }

  let html = this.getLabels()[0] + ': ' + toNanoDate((start_time_ext_ts + data.xHTML).toString());
  data.series.forEach(function (series) {
    if (!series.isVisible) return;
    let labeledData = series.labelHTML + ': ' + series.yHTML;
    if (series.isHighlighted) {
      labeledData = '<b>' + labeledData + '</b>';
    }
    html += '<br>' + series.dashHTML + ' ' + labeledData;
  });
  return html;
}

function generateWaveFormQuery(measurement, start, end, limit = 50000) {
  let query =  `
  from(bucket: "${bucket}")
    |> range(start: time(v: "${start}"), stop: time(v: "${end}"))
    |> filter(fn: (r) => r._measurement == "${measurement}")
    |> filter(fn: (r) => r["Customer"] == "${customer}")
    |> filter(fn: (r) => r["Site"] == "${site}")
    |> filter(fn: (r) => r["Transformer"] == "${transformer}")
    |> sort(columns: ["_time"])
    |> limit(n: ${limit})
`;
  if (device_type === 'vecto') {
    query += '|> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")';
  } else {
    query += `|> filter(fn: (r) => r["file_type"] == "OSCI")
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    `;
  }
  // console.log('generateWaveFormQuery', query);

  return query;
}

async function graph_waveforms_vecto(default_field) {
  let waveform_graph
  console.log(`graph_waveforms_vecto: ${default_field} Site=${site} time >= ${start_time_nanodate} AND time <= ${end_time_nanodate}`)
  const fluxQuery = generateWaveFormQuery("waveform", start_time_nanodate, end_time_nanodate);
  // Show loading text
  waveform_loadingDiv.style.display = "block";

  const waveform_data = await influx.collectRows(fluxQuery);
  // console.log(`graph_waveforms_vecto: waveform_data`, waveform_data);
  if (waveform_data.length === 0) {
    waveform_loadingDiv.querySelector("h3").innerText = no_waveform_text;
    return;
  }
  // Sort the data by timestamp
  waveform_data.sort((a, b) => new Date(a._time) - new Date(b._time));

  // const start_time = parseTimestampToNanoseconds(waveform_data[0]._time);
  const start_time = new Date(waveform_data[0]._time).getTime() * 1e6;
  console.log(`graph_waveforms_vecto: start_time`, start_time, waveform_data[0]._time);

  const waveform_voltage_values = waveform_data
    .filter((_, index) => index % filter === 0) // Include only every xth element, based on the ENV filter value
    .map((row, index) => {
    return [
      (new Date(row._time).getTime() * 1e6 - start_time),
      row[`${default_field}0`],
      row[`${default_field}1`],
      row[`${default_field}2`]
    ];
  });
  // console.log(`graph_waveforms_vecto: waveform_voltage_values`, waveform_voltage_values);

  waveform_graph = new Dygraph(
    document.getElementById("waveform"),
    waveform_voltage_values,
    {
      title: "Waveform graph",
      labels: ['time', 'AN', 'BN', 'CN'],
      xlabel: `nanoseconds since ${start_time__ext_nanodate}`,
      ylabel: default_field,
      labelsDiv: document.getElementById('waveform_legend'),
      legend: 'always',
      legendFormatter: legendFormatter,
      series: {
        'AN': {
          plotter: smoothPlotter,
          color: "red",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'BN': {
          plotter: smoothPlotter,
          color: "green",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'CN': {
          plotter: smoothPlotter,
          color: "blue",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'N': {
          plotter: smoothPlotter,
          color: "grey",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        }
      }
    }
  );
  // Hide loading text
  waveform_loadingDiv.style.display = "none";
  return waveform_graph;
}

function parseTimestampToNanoseconds(timestamp) {
  const [datePart, timePart] = timestamp.split('T');
  const [time, nanoseconds] = timePart.split('.');
  const nanosecondsPadded = (nanoseconds || '').padEnd(9, '0').slice(0, 9); // Ensure 9 digits for nanoseconds
  const milliseconds = new Date(`${datePart}T${time}`).getTime();
  return milliseconds * 1e6 + parseInt(nanosecondsPadded, 10);
}

async function graph_waveforms_aeberle(default_field) {
  let waveform_graph
  console.log(`graph_waveforms_aeberle: ${default_field} Site=${site} time >= ${start_time_utc_offset} AND time <= ${end_time_utc_offset}`)
  const fluxQuery = generateWaveFormQuery("waveform", start_time_utc_offset, end_time_utc_offset);
  // Show loading text
  waveform_loadingDiv.style.display = "block";

  const waveform_data = await influx.collectRows(fluxQuery);
  // console.log(`graph_waveforms_aeberle: waveform_data`, waveform_data);
  if (waveform_data.length === 0) {
    waveform_loadingDiv.querySelector("h3").innerText = no_waveform_text;
    return;
  }
  // Sort the data by timestamp
  waveform_data.sort((a, b) => new Date(a._time) - new Date(b._time));

  const start_time = parseTimestampToNanoseconds(waveform_data[0]._time);
  console.log(`graph_waveforms_aeberle: start_time`, start_time, waveform_data[0]._time);

  let previousTimeValue = 0;
  const waveform_voltage_values = waveform_data.map((row, index) => {
    const row_time = parseTimestampToNanoseconds(row._time);
    const timeValue = row_time - start_time;
    // Skip entries with negative or inconsistent time values
    if (timeValue < 0 || timeValue <= previousTimeValue) {
      // console.error(`Anomalous time value detected at index ${index}:`, row._time);
      return null; // Skip this entry
    }
    previousTimeValue = timeValue;
    if (default_field === 'I') {
      return [timeValue, row['IL1'], row['IL2'], row['IL3']];
    } else if (default_field === 'V') {
      return [timeValue, row['UL12'], row['UL23'], row['UL31']];
    }
  }).filter(entry => entry !== null);
  // console.log(`graph_waveforms_aeberle: waveform_voltage_values`, waveform_voltage_values);

  // smoothPlotter.smoothing = 0.53;
  const labels = default_field === 'I' ? ['time', 'I1', 'I2', 'I3'] : ['time', 'U12', 'U23', 'U31'];
  const series = default_field === 'I' ? {
    'I1': {
      color: "red",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'I2': {
      color: "green",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'I3': {
      color: "blue",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    }
  } : {
    'U12': {
      plotter: smoothPlotter,
      color: "red",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'U23': {
      plotter: smoothPlotter,
      color: "green",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'U31': {
      plotter: smoothPlotter,
      color: "blue",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    }
  };
  waveform_graph = new Dygraph(
    document.getElementById("waveform"),
    waveform_voltage_values,
    {
      title: "Waveform graph",
      labels: labels,
      xlabel: `nanoseconds since ${start_time__ext_nanodate}`,
      ylabel: default_field,
      labelsDiv: document.getElementById('waveform_legend'),
      legend: 'always',
      legendFormatter: legendFormatter,
      series: series,
      // plotter: Dygraph.smoothPlotter,
      // plotter: function (e) {
      //   // min=0 max=0.7 step=0.01
      //   smoothPlotter.smoothing = 0.13;
      //   return Dygraph.smoothPlotter(e);
      // }
    }
  );
  // Hide loading text
  waveform_loadingDiv.style.display = "none";
  return waveform_graph;
}

async function update_waveform_graph() {
  const default_field = document.getElementById('waveform_units').value
  console.log(`update_waveform_graph: ${default_field} Site=${site} time >= ${start_time_ext_RFC3339} AND time <= ${end_time_ext_RFC3339}`)
  const fluxQuery = generateWaveFormQuery("waveform", start_time_ext_RFC3339, end_time_ext_RFC3339);

  const waveform_data = await influx.collectRows(fluxQuery);
  // console.log('update_waveform_graph: waveform_data', waveform_data);
  if (waveform_data.length === 0) {
    return;
  }
  const start_time = new Date(waveform_data[0]._time).getTime() * 1e6;


  const waveform_voltage_values = waveform_data.map((row, index) => {
    return [
      (new Date(row._time).getTime() * 1e6 - start_time),
      row[`${default_field}0`],
      row[`${default_field}1`],
      row[`${default_field}2`]
    ];
  });
  // console.log('update_waveform_graph: waveform_voltage_values', waveform_voltage_values);

  waveform_graph.updateOptions({
    file: waveform_voltage_values,
    ylabel: default_field
  });
}


async function update_graph_units() {
  const default_field = document.getElementById('waveform_units').value
  console.log(`update_graph_units: ${default_field}`)
  await initialise_aeberle();
}

function generateCycleQuery(measurement, start, end, field, limit = 1000) {
  let query =  `
    from(bucket: "${bucket}")
      |> range(start: time(v: "${start}"), stop: time(v: "${end}"))
      |> filter(fn: (r) => r._measurement == "${measurement}")
      |> filter(fn: (r) => r["Customer"] == "${customer}")
      |> filter(fn: (r) => r["Site"] == "${site}")
      |> filter(fn: (r) => r["Transformer"] == "${transformer}")
      |> sort(columns: ["_time"])
      |> limit(n: ${limit})
  `;
  if (device_type === 'vecto') {
    query += `|> group(columns: ["Phase"])
    |> filter(fn: (r) => r["_field"] == "${field}")
          |> pivot(rowKey:["_time"], columnKey: ["Phase"], valueColumn: "_value")`;
  } else {
    query += `    |> filter(fn: (r) => r["file_type"] == "TRMS")
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")`;
  }
  // console.log('generateCycleQuery', query);

  return query;
}

async function graph_cycles_vecto(default_field) {
  console.log(`graph_cycles_vecto: ${default_field} time >= ${start_time_utc} AND time <= ${end_time_utc}`)
  let cycle_graph;
  const fluxQuery = generateCycleQuery("cycles", start_time_utc, end_time_utc, default_field);
  // Show loading text
  cycle_loadingDiv.style.display = "block";

  const cycle_data = await influx.collectRows(fluxQuery);
  // console.log('graph_cycles_vecto: cycle_data', cycle_data);
  if (cycle_data.length === 0) {
    cycle_loadingDiv.querySelector("h3").innerText = no_cycle_text;
    return;
  }
  const start_time = new Date(cycle_data[0]._time).getTime() * 1e6;
  const cycle_voltage_values = cycle_data.map((row, index) => {
    return [(new Date(row._time).getTime() * 1e6 - start_time), row["0"], row["1"], row["2"]];
  });
  // console.log('cycle_voltage_values', cycle_voltage_values);

  cycle_graph = new Dygraph(
    document.getElementById("cycle"),
    cycle_voltage_values,
    {
      title: "Cycle graph",
      labels: ['time', 'AN', 'BN', 'CN'],
      xlabel: `nanoseconds since ${start_time__ext_nanodate}`,
      ylabel: default_field,
      labelsDiv: document.getElementById('cycle_legend'),
      legend: 'always',
      legendFormatter: legendFormatter,
      series: {
        'AN': {
          color: "red",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'BN': {
          color: "green",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'CN': {
          color: "blue",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        },
        'N': {
          color: "grey",
          strokeWidth: 1.5,
          highlightCircleSize: 3
        }
      }
    }
  );
  // Hide loading text
  cycle_loadingDiv.style.display = "none";
  return cycle_graph;
}

async function graph_cycles_aeberle(default_field) {
  console.log(`graph_cycles_aeberle: ${default_field} time >= ${start_time_ext_ts} AND time <= ${end_time_ext_ts}`)
  let cycle_graph;
  const fluxQuery = generateCycleQuery("waveform", start_time_utc_offset, end_time_utc_offset, default_field);
  // Show loading text
  cycle_loadingDiv.style.display = "block";

  const cycle_data = await influx.collectRows(fluxQuery);
  // console.log('graph_cycles_aeberle: cycle_data', cycle_data);
  if (cycle_data.length === 0) {
    cycle_loadingDiv.querySelector("h3").innerText = no_cycle_text;
    return;
  }
  const start_time = new Date(cycle_data[0]._time).getTime() * 1e6;

  const cycle_voltage_values = cycle_data.map((row, index) => {
    const timeValue = (new Date(row._time).getTime() * 1e6 - start_time);
    if (default_field === 'I') {
      return [timeValue, row['IL1'], row['IL2'], row['IL3']];
    } else if (default_field === 'V') {
      return [timeValue, row['U12'], row['U23'], row['U31']];
    }
  });
  // console.log('cycle_voltage_values', cycle_voltage_values);

  const labels = default_field === 'I' ? ['time', 'I1', 'I2', 'I3'] : ['time', 'U12', 'U23', 'U31'];
  const series = default_field === 'I' ? {
    'I1': {
      color: "red",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'I2': {
      color: "green",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'I3': {
      color: "blue",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    }
  } : {
    'U12': {
      color: "red",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'U23': {
      color: "green",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    },
    'U31': {
      color: "blue",
      strokeWidth: 1.5,
      highlightCircleSize: 3
    }
  };
  cycle_graph = new Dygraph(
    document.getElementById("cycle"),
    cycle_voltage_values,
    {
      title: "Cycle graph",
      labels: labels,
      xlabel: `nanoseconds since ${start_time__ext_nanodate}`,
      ylabel: default_field,
      labelsDiv: document.getElementById('cycle_legend'),
      legend: 'always',
      legendFormatter: legendFormatter,
      series: series
    }
  );
  // Hide loading text
  cycle_loadingDiv.style.display = "none";
  return cycle_graph;
}

async function update_cycle_graph(){
  let default_field = document.getElementById('cycles_units').value
  console.log(`update_cycle_graph: ${default_field} Site=${site} time >= ${start_time_utc_offset} AND time <= ${end_time_utc_offset}`)
  const fluxQuery = generateCycleQuery("cycles", start_time_utc_offset, end_time_utc_offset, default_field);

  const cycle_data = await influx.collectRows(fluxQuery);
  console.log('update_cycle_graph: cycle_data', cycle_data);
  if (cycle_data.length === 0) {
    return;
  }
  const start_time = new Date(cycle_data[0]._time).getTime() * 1e6;

  const cycle_voltage_values = cycle_data.map((row, index) => {
    return [(new Date(row._time).getTime() * 1e6 - start_time), row["0"], row["1"], row["2"]];
  });
  console.log('update_cycle_graph: cycle_voltage_values', cycle_voltage_values);

  cycle_graph.updateOptions({
    file: cycle_voltage_values,
    ylabel: default_field
  });
}

function toUTCTimeZone(timestamp, offsetHours) {
  // Parse the original timestamp
  const milliseconds = Math.floor(timestamp / 1e6);
  // const nanoseconds = timestamp % 1e6;
  const date = new Date(milliseconds);

  // Add the timezone offset in milliseconds
  const offsetMilliseconds = offsetHours * 60 * 60 * 1000;
  const newDate = new Date(date.getTime() + offsetMilliseconds);

  return newDate.toISOString();
}

function toRFC3339(timestamp, offsetHours) {
  // Parse the original timestamp
  const milliseconds = Math.floor(timestamp / 1e6);
  // const nanoseconds = timestamp % 1e6;
  const date = new Date(milliseconds);

  const newDate = new Date(date.getTime());

  // Format the new date to the desired string format
  const isoString = newDate.toISOString().replace('Z', '');
  const offsetString = (offsetHours >= 0 ? '+' : '-') +
    String(Math.abs(offsetHours)).padStart(2, '0') +
    ':00';

  return `${isoString}${offsetString}`;
}

function toNanoDate(timestamp) {
  const milliseconds = Math.floor(timestamp / 1e6);
  const nanoseconds = timestamp % 1e6;
  const date = new Date(milliseconds);
  const isoString = date.toISOString().replace('Z', '');
  return `${isoString}${String(nanoseconds).padStart(6, '0')}Z`;}

async function initialise_vecto() {
  const element = document.getElementById("event_title");
  element.innerHTML = `<b>Event type:</b> ${event_type}, <b>Start:</b> ${start_time_nanodate}, <b>Phases:</b> ${phases}`;

  document.title = event_type

  influx = await influxdb_connect()

  const default_cycle_units = await get_cycle_fields(default_measurement);
  const default_waveform_units = document.getElementById('waveform_units');

  cycle_graph = await graph_cycles_vecto(default_cycle_units.value)
  waveform_graph = await graph_waveforms_vecto(default_waveform_units.value)
  // console.log('graphs', waveform_graph, cycle_graph);
  if(waveform_graph && cycle_graph) {
    Dygraph.synchronize([waveform_graph, cycle_graph], {
      selection: true,
      zoom: true,
      range: false
    })
  }
  default_cycle_units.addEventListener("change", update_cycle_graph);
  default_waveform_units.addEventListener("change", update_waveform_graph);
}

async function initialise_aeberle() {
  const element = document.getElementById("event_title");
  element.innerHTML = `<b>Event type:</b> ${event_type}, <b>Start:</b> ${start_time_nanodate}, <b>Phases:</b> ${phases}`;

  document.title = event_type

  influx = await influxdb_connect()

  const default_waveform_units = document.getElementById('waveform_units')

  cycle_graph = await graph_cycles_aeberle(default_waveform_units.value)
  waveform_graph = await graph_waveforms_aeberle(default_waveform_units.value)
  // console.log('graphs', waveform_graph, cycle_graph);
  if(!waveform_graph && !cycle_graph) {
    document.getElementById("data_ok").style.display = "block";
  } else {
    Dygraph.synchronize([waveform_graph, cycle_graph], {
      selection: true,
      zoom: true,
      range: false
    })
  }
  default_waveform_units.addEventListener("change", update_graph_units);
}
let influx;
let waveform_graph;
let cycle_graph;
let customer, site, transformer, device_type, bucket;

const queryString = window.location.search;
// console.log(queryString);
// Set loading/data div elements
const cycle_loadingDiv = document.getElementById("cycle_loading");
const waveform_loadingDiv = document.getElementById("waveform_loading");
cycle_loadingDiv.querySelector("h3").innerText = cycle_loading_text;
waveform_loadingDiv.querySelector("h3").innerText = waveform_loading_text;

const urlParams = new URLSearchParams(queryString);
const start_time_ts = parseInt(urlParams.get('start'));
const end_time_ts = parseInt(urlParams.get('end'));
const event_type = urlParams.get('type');
const phases = urlParams.get('phases');

customer = urlParams.get('customer');
site = urlParams.get('site');
transformer = urlParams.get('transformer');
device_type = urlParams.get('device_type') || 'vecto';
console.log("customer-site-transformer-device_type", customer, site, transformer, device_type);
console.log("before_time-after-time-filter", before_time, after_time, filter);

// Get the local time zone offset in milliseconds
const timezoneOffset = -(new Date().getTimezoneOffset() / 60);
// Normalize waveform time by adding the local time zone offset
const start_time_ext_ts = start_time_ts - before_time;
const end_time_ext_ts = end_time_ts + after_time;
// console.log('start_time-end_time', start_time_ext_ts, end_time_ext_ts);
const start_time_ext_RFC3339  = toRFC3339(start_time_ext_ts, timezoneOffset);
const end_time_ext_RFC3339  = toRFC3339(end_time_ext_ts, timezoneOffset);
console.log('start_timeRFC-end_timeRFC', start_time_ext_RFC3339, end_time_ext_RFC3339);
const start_time_utc = toUTCTimeZone(start_time_ext_ts, 0);
const end_time_utc = toUTCTimeZone(end_time_ext_ts, 0);
const start_time_utc_offset = toUTCTimeZone(start_time_ext_ts, timezoneOffset);
const end_time_utc_offset = toUTCTimeZone(end_time_ext_ts, timezoneOffset);
console.log('start_time_offset-end_time-offset-offset', start_time_utc_offset, end_time_utc_offset, timezoneOffset);

const start_time_nanodate = toNanoDate(start_time_ts.toString())
const end_time_nanodate = toNanoDate(end_time_ts.toString())
const start_time__ext_nanodate = toNanoDate(start_time_ext_ts.toString())

console.log('start_time_nanodate-start_time__ext_nanodate', start_time_nanodate, start_time__ext_nanodate);

const cycleInfoDiv = document.getElementById('cycle_info');
let default_measurement = "waveform"
if (device_type === 'vecto') {
  // cycleInfoDiv.style.display = 'block';
  default_measurement = "cycles"
  initialise_vecto().then(() => {
    console.log('initialise_vecto completed');
  });
} else {
  // cycleInfoDiv.style.display = 'none';
  initialise_aeberle().then(() => {
    console.log('initialise_aeberle completed');
  });
}

