/** InfluxDB v2 URL */
const postgresqlurl = process.env['POSTGRESQLURL'] || ''
const before_time = Number(process.env['BEFORE_TIME']) * 1e6|| 100000000 // 100ms
const after_time = Number(process.env['AFTER_TIME']) * 1e6 || 8000000000; // 8s
const filter = Number(process.env['DATA_FILTER']) || ''

if (!postgresqlurl) {
  throw new Error('Missing required environment variables: POSTGRESQLURL');
}

export { postgresqlurl, before_time, after_time, filter }
